import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import isUserHasProfile from 'helpers/profileValidator';
import { CREDIT_POLICY_WRITE } from 'helpers/profilePermission';

import PageContainer from '../PageContainer';
import CreditPolicyListTable from './CreditPolicyListTable';
import { getData, cancelFetch } from '../../redux-store/actions/index';
import {
  CP_GET_LIST,
  CP_GET_PLAZOS_CREDITO,
  GET_MONEDAS,
  GET_PRODUCTOS,
} from '../../redux-store/constants/action-types';
import { API, BACKEND_DF, BASE_URL, FRONT_DF_1, SEARCH_DELAY_MS } from '../../CONST';

class CreditPolicyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFilter: { value: undefined, label: '' },
    };
    this.filtering = false;
    this.filterUrl = '';
    this.fetchDataWithDebounce = _.debounce(this.fetchData, SEARCH_DELAY_MS);
  }

  componentDidMount() {
    const { dispatchGetData } = this.props;
    dispatchGetData({
      url: `${BASE_URL}${API.monedas}`,
      type: GET_MONEDAS,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.productos}`,
      type: GET_PRODUCTOS,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.plazos_credito}/`,
      type: CP_GET_PLAZOS_CREDITO,
    });
  }

  onFilteredChange = () => {
    this.filtering = true;
  };

  fetchStrategy = (tableState) => {
    if (this.filtering) {
      return this.fetchDataWithDebounce(tableState);
    }
    return this.fetchData(tableState);
  };

  handleFilter = (option) => {
    this.setState({
      activeFilter: option,
    });
  };

  fetchData = (state) => {
    const { dispatchGetData, dispatchCancelFetch } = this.props;
    this.filtering = false;

    let filterUlr = `${BASE_URL}${API.creditos}/?page=${state.page + 1}&`;
    if (state.filtered.length) {
      state.filtered.forEach((node) => {
        let validFilter = false;
        let filterValue = node.value;

        if (typeof node.value === 'number' && node.value > 0) {
          validFilter = true;
        } else if (
          typeof node.value === 'string' &&
          node.value.length > 2 &&
          node.value !== 'all-options'
        ) {
          validFilter = true;

          const dateFields = ['emision', 'balance__inicio_vigencia', 'balance__termino_vigencia'];
          if (dateFields.includes(node.id)) {
            const date = moment(filterValue, FRONT_DF_1, true);
            if (date.isValid()) {
              filterValue = date.format(BACKEND_DF);
            } else {
              validFilter = false;
            }
          }
        } else if (node.id === 'vigentes') {
          validFilter = true;
        }
        if (validFilter) {
          filterUlr = `${filterUlr}${node.id}=${filterValue}&`;
        }
      });
    }

    if (state.sorted.length) {
      let ordering = 'ordering=';
      const node = state.sorted[0];
      const field = node.id.replace('__icontains', '');
      ordering = !node.desc ? `${ordering}${field}` : `${ordering}-${field}`;
      filterUlr = `${filterUlr}${ordering}`;
    }

    // only fetch data if filters have changed
    if (filterUlr !== this.filterUrl) {
      this.filterUrl = filterUlr;

			dispatchCancelFetch();

      dispatchGetData({
        url: filterUlr,
        type: CP_GET_LIST,
      });
    }
  };

  render() {
    const { activeFilter } = this.state;
    return (
      <PageContainer
        breadcrumbs={[{ name: 'CRÉDITO', url: '/credit/policies/' }, { name: 'PÓLIZAS' }]}
        mainAction={
          isUserHasProfile(CREDIT_POLICY_WRITE) && {
            url: '/credit/policies/create',
            caption: 'Crear póliza',
          }
        }
      >
        <CreditPolicyListTable
          activeFilter={activeFilter}
          onChange={this.handleFilter}
          onFetchData={this.fetchStrategy}
          onFilteredChange={this.onFilteredChange}
        />
      </PageContainer>
    );
  }
}

CreditPolicyList.propTypes = {
  dispatchGetData: PropTypes.func.isRequired,
  dispatchCancelFetch: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  dispatchGetData: (callConfig) => dispatch(getData(callConfig)),
	dispatchCancelFetch: (callConfig) => dispatch(cancelFetch(callConfig)),
});

export default connect(
  null,
  mapDispatchToProps,
)(CreditPolicyList);
