import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import _ from 'lodash';

import PageContainer from '../PageContainer';
import GeneralConditionedListTable from './GeneralConditionedListTable';
import { getData, patchData, cancelFetch } from '../../redux-store/actions/index';
import {
  ACTIVATE_GENERAL_CONDITIONED,
  GET_GENERAL_CONDITIONED_LIST,
} from '../../redux-store/constants/action-types';
import { API, BACKEND_DF, BASE_URL, FRONT_DF_2, SEARCH_DELAY_MS } from '../../CONST';
import isUserHasProfile from '../../helpers/profileValidator';
import { GENERAL_CONDITIONED_WRITE } from '../../helpers/profilePermission';

const moment = require('moment');

const api = API.maestras;

class GeneralConditionedList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      activeFilter: { value: undefined, label: '' },
    };
    this.filtering = false;
    this.lastFilter = '';

    this.onFilteredChange = this.onFilteredChange.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchDataWithDebounce = _.debounce(this.fetchData, SEARCH_DELAY_MS);
    this.fetchStrategy = this.fetchStrategy.bind(this);
    this.onClick = this.onClick.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
  }

  onClick(e, instance) {
    const { dispatchPatchData } = this.props;
    e.preventDefault();
    dispatchPatchData({
      url: `${BASE_URL}${api}/${instance.id}/`,
      config: { activa: !instance.activa },
      type: ACTIVATE_GENERAL_CONDITIONED,
    });
  }

  onFilteredChange() {
    this.filtering = true;
  }

  handleFilter(option) {
    this.setState({
      activeFilter: option,
    });
  }

  fetchStrategy(tableState) {
    if (this.filtering) {
      return this.fetchDataWithDebounce(tableState);
    }
    return this.fetchData(tableState);
  }

  fetchData(state) {
    const { dispatchGetData, dispatchCancelFetch } = this.props;
    let filters = `${BASE_URL}${api}/?page=${state.page + 1}&`;
    if (state.filtered.length) {
      state.filtered.forEach((node) => {
        let validFilter = false;
        let filterValue = node.value;

        if (typeof node.value === 'number' && node.value > 0) {
          filterValue = node.value;
          validFilter = true;
        } else if (typeof node.value === 'string' && node.value.length > 2) {
          filterValue = node.value;
          validFilter = true;

          if (node.id === 'inicio_vigencia') {
            const date = moment(filterValue, FRONT_DF_2, true);
            if (date.isValid()) {
              filterValue = date.format(BACKEND_DF);
            } else {
              validFilter = false;
            }
          }
        }

        if (validFilter) {
          filters = `${filters}${node.id}=${filterValue}&`;
        }
      });
    }

    if (state.sorted.length) {
      let ordering = 'ordering=';
      state.sorted.forEach((node) => {
        const field = node.id.replace('__icontains', '');
        ordering = !node.desc ? `${ordering}${field}` : `${ordering}-${field}`;
      });
      filters = `${filters}${ordering}`;
    }

    // only fetch data if filters have changed
    if (filters !== this.lastFilter) {
      this.lastFilter = filters;
      this.setState({ loading: true });

			dispatchCancelFetch();

      dispatchGetData({
        url: filters,
        name: 'conditioned',
        type: GET_GENERAL_CONDITIONED_LIST,
      });

      this.setState({ loading: false });
    }
  }

  render() {
    const { activeFilter, loading } = this.state;
    return (
      <PageContainer breadcrumbs={[{ name: 'CONDICIONADOS GENERALES' }]}>
        <Col md={{ offset: 10 }}>
          {isUserHasProfile(GENERAL_CONDITIONED_WRITE) && (
            <Link className="btn btn-orsan" disabled={false} to="/condicionados/create">
              Crear Condicionado General
            </Link>
          )}
        </Col>

        <GeneralConditionedListTable
          activeFilter={activeFilter}
          handleOnClick={this.onClick}
          loading={loading}
          onChange={this.handleFilter}
          onFetchData={this.fetchStrategy}
          onFilteredChange={this.onFilteredChange}
        />
      </PageContainer>
    );
  }
}

GeneralConditionedList.propTypes = {
  dispatchGetData: PropTypes.func.isRequired,
  dispatchPatchData: PropTypes.func.isRequired,
  dispatchCancelFetch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  conditioned: state.conditioned,
  login: state.login,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchGetData: (conditioned) => dispatch(getData(conditioned)),
  dispatchPatchData: (conditioned) => dispatch(patchData(conditioned)),
	dispatchCancelFetch: (conditioned) => dispatch(cancelFetch(conditioned)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GeneralConditionedList);
