import {
  CLEAN_ENDOSO,
  CLEAN_POLICY,
  CREATE_ENDOSO,
  CREATE_POLICY,
  DELETE_ENDOSO,
  DELETE_POLICY,
  SAVE_POLICY_ERROR,
  SAVE_ENDOSO_ERROR,
  GET_ACTIVE_GENERAL_CONDITIONED_LIST,
  GET_ACTIVIDAD_LIST,
  GET_ALIASES,
  GET_BALANCE,
  GET_ENDOSO_LIST,
  GET_INTERMEDIARIO_LIST,
  GET_MONEDAS,
  GET_POLICY,
  GET_POLICY_LIST,
  GET_TIPOS_ENDOSO,
  POLICY_DELETE_ADJUNTO,
  PUBLISH_ENDOSO,
  RESET_ERROR,
  SAVE_ENDOSO,
  SAVE_POLICY,
  GET_ACTIVIDADES,
  startType,
  errorType,
  CLEAR_PUBLISH_ENDOSO_ERROR,
  CP_GET,
  CP_EDIT,
  CP_EDIT_AND_CONTINUE,
} from '../constants/action-types';
import {
  ID_RAMOFECU_GARANTIA,
  ID_RAMOFECU_CREDITO,
  ID_RAMOFECU_CREDITO_EXPORTACION,
} from '../../CONST';

const initialState = {
  adjuntos_uuids: [],
  aliasList: [],
  actividadesList: [],
  balance: undefined,
  condicionadosGenerales: [],
  endoso_type_list: [],
  endosoList: [],
  errorEndosoMessage: undefined,
  errorMessage: undefined,
  listData: {
    data: [],
    pages: 0,
    count: undefined,
    next: undefined,
    previous: undefined,
  },
  selectedEndoso: undefined,
  selectedPolicy: undefined,
  successEndosoMessage: undefined,
  successLoadList: undefined,
  successMessage: undefined,
  successPolicyDelete: undefined,
  tiposIntermediario: [],
  tiposMoneda: [],
  actividadList: [],
  actividadGarantiaList: [],
  actividadCreditoList: [],
  actividadCreditoExportacionList: [],
  loading: false,
  error: undefined,
	fetchAbortController: undefined,
};

const aliasesToOptions = aliases => aliases.map(node => ({ label: node.alias, value: node.id }));

const actividadesToOptions = actividades => actividades.map(
  node => ({ label: `${node.codigo} - ${node.nombre}`, value: node.id }));

const executeGetAliases = (state, action) => ({
  ...state,
  aliasList: aliasesToOptions(action.payload),
});

const executeGetActividades = (state, action) => ({
  ...state,
  actividadesList: actividadesToOptions(action.payload),
});

const executeRefreshOptionsFromCreditPolicy = (state, action) => ({
  ...state,
  aliasList: action.payload.base.asegurado.aliasrazonsocial_set.map(
    node => ({ label: node.alias, value: node.id })),
  actividadesList: actividadesToOptions(action.payload.base.asegurado.actividades),
});

const executeGetPolicySuccess = (state, action) => ({
  ...state,
  selectedPolicy: action.payload,
  successMessage: undefined,
  errorMessage: undefined,
});

const setAbortController = (state, action) => ({
	...state,
	fetchAbortController: action.abortController
});

const executeGetPolicyListSuccess = (state, action) => ({
    ...state,
    listData: {
      data: action.payload.results,
      pages: Math.ceil(action.payload.count / 10),
      count: action.payload.count,
      next: action.payload.next,
      previous: action.payload.previous,
    },
    successLoadList: true,
    errorMessage: undefined,
  });

const executeGetMoneyTypesSuccess = (state, action) => {
  const tiposMoneda = action.payload.map(node => ({ label: node.descripcion, value: node }));
  return {
    ...state,
    tiposMoneda,
    errorMessage: undefined,
  };
};

const executeCreatePolicySuccess = (state, action) => ({
  ...state,
  selectedPolicy: action.payload,
  successMessage: true,
  errorMessage: undefined,
});

const executeCreateEndosoSuccess = (state, action) => {
  const newEndoso = action.payload;
  return {
    ...state,
    endosoList: [
      ...state.endosoList,
      newEndoso,
    ],
    successEndosoMessage: true,
    errorEndosoMessage: undefined,
  };
};

const executeSaveEndosoSuccess = (state, action) => {
  const updatedEndoso = action.payload;
  const endoso = state.endosoList.find(node => node.id === action.payload.id);
  const index = state.endosoList.indexOf(endoso);
  state.endosoList.splice(index, 1, updatedEndoso);
  return {
    ...state,
    successEndosoMessage: true,
    errorEndosoMessage: undefined,
  };
};

const executeSavePolicySuccess = state => ({
  ...state,
  successMessage: true,
  errorMessage: undefined,
});

const executeDeletePolicySuccess = state => ({
  ...state,
  successPolicyDelete: true,
  errorMessage: undefined,
});

const executeCleanPolicySuccess = state => ({
  ...state,
  selectedPolicy: undefined,
  errorMessage: undefined,
  errorEndoso: undefined,
  successLoadList: undefined,
  successMessage: undefined,
  successPolicyDelete: undefined,
});

const executeCleanEndosoSuccess = state => ({
  ...state,
  endoso_type_list: [],
  selectedEndoso: undefined,
  successEndosoMessage: undefined,
  errorEndosoMessage: undefined,
});

const executeGetGeneralConditionedSuccess = (state, action) => {
  const condicionadosGenerales = action.payload.map(node => ({
    label: node.nemotecnico,
    value: node,
  }));
  return {
    ...state,
    condicionadosGenerales,
    errorMessage: undefined,
  };
};

const executeGetActivityListSuccess = (state, action) => {
  const actividadList = action.payload.map(node => ({ label: node.descripcion, value: node }));
  return {
    ...state,
    actividadList,
    actividadGarantiaList: actividadList.filter(i => i.value.ramos_fecu.includes(ID_RAMOFECU_GARANTIA)),
    actividadCreditoList: actividadList.filter(i => i.value.ramos_fecu.includes(ID_RAMOFECU_CREDITO)),
    actividadCreditoExportacionList: actividadList.filter(i => i.value.ramos_fecu.includes(ID_RAMOFECU_CREDITO_EXPORTACION)),
    errorMessage: undefined,
  };
};

const executeGetIntermediarioListSuccess = (state, action) => {
  const tiposIntermediario = action.payload.map(node => ({
    label: node.descripcion,
    value: node,
  }));
  return {
    ...state,
    tiposIntermediario,
    errorMessage: undefined,
  };
};

const executeGetEndosoListSuccess = (state, action) => ({
  ...state,
  endosoList: action.payload,
  successMessage: undefined,
  errorMessage: undefined,
});

const executeGetTiposEndosoSuccess = (state, action) => ({
  ...state,
  endoso_type_list: action.payload,
  errorMessage: undefined,
});

const executeErrorMessage = (state, action) => ({
  ...state,
  errorMessage: action.payload || 'No se pudo completar la acción, por favor inténtelo más tarde',
});

const executeSaveEndosoErrorMessage = state => ({
  ...state,
  errorEndosoMessage: true,
});

const executeResetErrorMessage = state => ({
  ...state,
  errorEndosoMessage: undefined,
});

const executeDeleteEndosoSuccess = (state, action) => ({
  ...state,
  endosoList: state.endosoList.filter(endoso => endoso.id !== action.payload.id),
  successEndosoMessage: true,
  errorEndosoMessage: undefined,
});

const executeGetBalanceSuccess = (state, action) => ({
  ...state,
  balance: action.payload,
  errorEndosoMessage: undefined,
});

const startExecutePublishEndoso = state => {
  return {
    ...state,
    loading: true,
  }
};


const executePublishEndosoSuccess = (state, action) => {
  const updatedEndoso = action.payload;
  const endoso = state.endosoList.find(node => node.id === action.payload.id);
  const index = state.endosoList.indexOf(endoso);
  state.endosoList.splice(index, 1, updatedEndoso);
  return {
    ...state,
    successEndosoMessage: true,
    error: updatedEndoso.errors ? { errors: updatedEndoso.errors } : undefined,
    errorEndosoMessage: undefined,
    loading: false,
  };
};

const executePublishEndosoError = (state, action) => {
  return {
    ...state,
    loading: false,
    successEndosoMessage: false,
    error: action.payload.body,
  }
};

const clearExecutePublishEndosoError = state => {
  return {
    ...state,
    error: undefined,
  }
};

const deleteAdjunto = (state, action) => ({
  ...state,
  selectedPolicy: {
    ...state.selectedPolicy,
    adjuntos_ids: state.selectedPolicy.adjuntos_ids.filter(id => id !== action.payload.id),
    adjuntos: state.selectedPolicy.adjuntos.filter(adjunto => adjunto.id !== action.payload.id),
  }
});

function policies(state = initialState, action) {
  switch (action.type) {
    case GET_ALIASES:
      return executeGetAliases(state, action);
    case GET_ACTIVIDADES:
      return executeGetActividades(state, action);
		case startType(GET_POLICY_LIST):
			return setAbortController(state, action);
    case GET_POLICY_LIST:
      return executeGetPolicyListSuccess(state, action);
    case GET_POLICY:
      return executeGetPolicySuccess(state, action);
    case GET_MONEDAS:
      return executeGetMoneyTypesSuccess(state, action);
    case GET_ACTIVE_GENERAL_CONDITIONED_LIST:
      return executeGetGeneralConditionedSuccess(state, action);
    case GET_INTERMEDIARIO_LIST:
      return executeGetIntermediarioListSuccess(state, action);
    case GET_ACTIVIDAD_LIST:
      return executeGetActivityListSuccess(state, action);
    case CREATE_POLICY:
      return executeCreatePolicySuccess(state, action);
    case SAVE_POLICY:
      return executeSavePolicySuccess(state, action);
    case DELETE_POLICY:
      return executeDeletePolicySuccess(state, action);
    case CLEAN_POLICY:
      return executeCleanPolicySuccess(state, action);
    case CLEAN_ENDOSO:
      return executeCleanEndosoSuccess(state, action);
    case GET_TIPOS_ENDOSO:
      return executeGetTiposEndosoSuccess(state, action);
    case GET_ENDOSO_LIST:
      return executeGetEndosoListSuccess(state, action);
    case CREATE_ENDOSO:
      return executeCreateEndosoSuccess(state, action);
    case SAVE_ENDOSO:
      return executeSaveEndosoSuccess(state, action);
    case startType(PUBLISH_ENDOSO):
      return startExecutePublishEndoso(state);
    case PUBLISH_ENDOSO:
      return executePublishEndosoSuccess(state, action);
    case errorType(PUBLISH_ENDOSO):
      return executePublishEndosoError(state, action);
    case CLEAR_PUBLISH_ENDOSO_ERROR:
      return clearExecutePublishEndosoError(state);
    case GET_BALANCE:
      return executeGetBalanceSuccess(state, action);
    case DELETE_ENDOSO:
      return executeDeleteEndosoSuccess(state, action);
    case RESET_ERROR:
      return executeResetErrorMessage(state, action);
    case SAVE_POLICY_ERROR:
      return executeErrorMessage(state, action);
    case SAVE_ENDOSO_ERROR:
      return executeSaveEndosoErrorMessage(state, action);
    case POLICY_DELETE_ADJUNTO:
      return deleteAdjunto(state, action);
    case CP_GET:
      return executeRefreshOptionsFromCreditPolicy(state, action);
    case CP_EDIT:
      return executeRefreshOptionsFromCreditPolicy(state, action);
    case CP_EDIT_AND_CONTINUE:
      return executeRefreshOptionsFromCreditPolicy(state, action);
    default:
      return state;
  }
}

export default policies;
