import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import PageContainer from '../PageContainer';
import PolicyListTable from './PolicyListTable';
import { cleanPolicy, deleteData, getData, cancelFetch } from '../../redux-store/actions/index';
import {
  DELETE_POLICY,
  GET_MONEDAS,
  GET_POLICY_LIST,
  GET_TIPOS_COBERTURA,
} from '../../redux-store/constants/action-types';
import {
  API,
  BACKEND_DF,
  BASE_URL,
  FRONT_DF_1,
  ID_RAMOFECU_GARANTIA,
  SEARCH_DELAY_MS,
} from '../../CONST';

import isUserHasProfile from '../../helpers/profileValidator';
import { POLICY_WRITE } from '../../helpers/profilePermission';

class PolicyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      activeFilter: { value: undefined, label: '' },
      deleteWarningOpen: false,
      deletePolicyId: undefined,
    };
    this.filtering = false;
    this.lastFilter = '';
    this.fetchDataWithDebounce = _.debounce(this.fetchData, SEARCH_DELAY_MS);
  }

  componentDidMount() {
    const { dispatchGetData } = this.props;

    dispatchGetData({
      url: `${BASE_URL}${API.tipos_cobertura}?ramo_asegurado=${ID_RAMOFECU_GARANTIA}`,
      type: GET_TIPOS_COBERTURA,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.monedas}`,
      type: GET_MONEDAS,
    });
  }

  onFilteredChange = () => {
    this.filtering = true;
  };

  fetchStrategy = (tableState) => {
    if (this.filtering) {
      return this.fetchDataWithDebounce(tableState);
    }
    return this.fetchData(tableState);
  };

  handleFilter = (option) => {
    this.setState({
      activeFilter: option,
    });
  };

  fetchData = (state) => {
    const { dispatchGetData, dispatchCancelFetch } = this.props;

    this.filtering = false;

    let filters = `${BASE_URL}${API.garantias}/?page=${state.page + 1}&`;
    if (state.filtered.length) {
      state.filtered.forEach((node) => {
        let validFilter = false;
        let filterValue = node.value;

        if (typeof node.value === 'number' && node.value > 0) {
          validFilter = true;
        } else if (typeof node.value === 'string' && node.value.length > 2) {
          validFilter = true;

          const dateFields = [
            'emision',
            'balancegarantia__inicio_vigencia',
            'balancegarantia__termino_vigencia',
          ];
          if (dateFields.includes(node.id)) {
            const date = moment(filterValue, FRONT_DF_1, true);
            if (date.isValid()) {
              filterValue = date.format(BACKEND_DF);
            } else {
              validFilter = false;
            }
          }
        } else if (node.id === 'vigentes') {
          validFilter = true;
        }
        if (validFilter) {
          filters = `${filters}${node.id}=${filterValue}&`;
        }
      });
    }

    if (state.sorted.length) {
      const node = state.sorted[0];
      let ordering = node.desc ? '-' : '';
      if (node.id === 'base__tipo_cobertura__id') {
        ordering += 'base__tipo_cobertura__nemotecnico';
      } else if (node.id === 'base__moneda_asegurada__id') {
        ordering += 'base__moneda_asegurada__nombre_corto';
      } else {
        const field = node.id.replace('__icontains', '');
        ordering += field;
      }
      filters = `${filters}&ordering=${ordering}`;
    }

    // only fetch data if filters have changed
    if (filters !== this.lastFilter) {
      this.lastFilter = filters;
      this.setState({ loading: true });

			dispatchCancelFetch();

      dispatchGetData({
        url: filters,
        name: 'policies',
        type: GET_POLICY_LIST,
      });
    }
  };

  handleDeleteWarning = (id) => {
    const { deleteWarningOpen } = this.state;
    this.setState({
      deleteWarningOpen: !deleteWarningOpen,
      deletePolicyId: !deleteWarningOpen ? id : undefined,
    });
  };

  deletePolicy = (id) => {
    const { dispatchDeleteData } = this.props;
    dispatchDeleteData({
      url: `${BASE_URL}${API.garantias}/${id}/`,
      type: DELETE_POLICY,
    });
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps) {
    const { dispatchCleanPolicy, dispatchGetData } = this.props;

    if (nextProps.policies.successLoadList) {
      dispatchCleanPolicy();
      this.setState({ loading: false });
    }
    if (nextProps.policies.successPolicyDelete) {
      dispatchCleanPolicy();
      dispatchGetData({
        url: `${BASE_URL}${API.garantias}/?`,
        name: 'policies',
        type: GET_POLICY_LIST,
      });
      this.setState({
        loading: true,
        deleteWarningOpen: false,
      });
    }
  }

  render() {
    const { activeFilter, loading, deletePolicyId, deleteWarningOpen } = this.state;
    return (
      <PageContainer
        breadcrumbs={[{ name: 'GARANTÍA', url: '/guarantee/policies/' }, { name: 'PÓLIZAS' }]}
        mainAction={
          isUserHasProfile(POLICY_WRITE) && {
            url: '/guarantee/policies/create',
            caption: 'Crear póliza',
          }
        }
      >
        <PolicyListTable
          activeFilter={activeFilter}
          handleDelete={this.deletePolicy}
          loading={loading}
          onChange={this.handleFilter}
          onFetchData={this.fetchStrategy}
          onFilteredChange={this.onFilteredChange}
          deleteWarningOpen={deleteWarningOpen}
          handleDeleteWarning={this.handleDeleteWarning}
          deletePolicyId={deletePolicyId}
        />
      </PageContainer>
    );
  }
}

PolicyList.propTypes = {
  policies: PropTypes.shape({
    successLoadList: PropTypes.bool,
    successPolicyDelete: PropTypes.bool,
  }).isRequired,
  dispatchCleanPolicy: PropTypes.func.isRequired,
  dispatchDeleteData: PropTypes.func.isRequired,
  dispatchGetData: PropTypes.func.isRequired,
  dispatchCancelFetch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  policies: state.policies,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchCleanPolicy: (policy) => dispatch(cleanPolicy(policy)),
  dispatchDeleteData: (policy) => dispatch(deleteData(policy)),
  dispatchGetData: (policy) => dispatch(getData(policy)),
  dispatchCancelFetch: (policy) => dispatch(cancelFetch(policy)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PolicyList);
