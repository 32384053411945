import {
  CLEAN_GENERAL_CONDITIONED,
  CLEAN_POLICY,
  CLEAN_ENDOSO,
	CP_GET_LIST,
	GET_GENERAL_CONDITIONED_LIST,
	GET_POLICY_LIST,
  RESET_ERROR,
  TOKEN_ERROR,
  startType,
  errorType,
} from '../constants/action-types';
import auth from '../../helpers/auth';

// todo: sacar estas funciones a otros archivos de actions particulares o despacharlas directamente
export function alertModal(payload) {
  return { type: TOKEN_ERROR, payload };
}

export function cleanGeneralConditioned(payload) {
  return { type: CLEAN_GENERAL_CONDITIONED, payload };
}

export function cleanPolicy(payload) {
  return { type: CLEAN_POLICY, payload };
}

export function resetError(payload) {
  return { type: RESET_ERROR, payload };
}

export function cleanEndoso(payload) {
  return { type: CLEAN_ENDOSO, payload };
}
// -------------------------------------------------------------------------------------------------

export const executePromiseWithAbortSignal = (dispatch, object, promise) => {
  const abortController = new AbortController();
  const { signal } = abortController;

  dispatch({ type: startType(object.type), abortController });

  const promiseWithAbortSignal = promise(signal);

  return promiseWithAbortSignal
    .then((response) => {
      if (!signal.aborted)
        dispatch({ type: object.type, payload: response });
    })
    .catch((error) => {
      if (signal.aborted) return;

      const errorActionType = errorType(object.type);
      try {
        error.json().then((json) =>
          dispatch({
            type: errorActionType,
            payload: { error, body: json },
          }),
        );
      } catch {
        dispatch({ type: errorActionType, payload: { error, body: {} } });
      }

      if (object.onFailedReq) {
        object.onFailedReq();
      }
    });
};

export const executePromise = (dispatch, object, promise) => {
  dispatch({ type: startType(object.type) });
  return promise
    .then((response) => dispatch({ type: object.type, payload: response }))
    .catch((error) => {
      const errorActionType = errorType(object.type);
      try {
        error.json().then((json) =>
          dispatch({
            type: errorActionType,
            payload: { error, body: json },
          }),
        );
      } catch {
        dispatch({ type: errorActionType, payload: { error, body: {} } });
      }

      if (object.onFailedReq) {
        object.onFailedReq();
      }
    });
};

// eslint-disable-nextline no-unused-vars
export const cancelFetch = () => (dispatch, getState) => {
	const { policies, creditPolicy, conditioned } = getState();

	let fetchAbortController;

	fetchAbortController = policies.fetchAbortController ? policies.fetchAbortController : fetchAbortController;
	fetchAbortController = creditPolicy.fetchAbortController ? creditPolicy.fetchAbortController : fetchAbortController;
	fetchAbortController = conditioned.fetchAbortController ? conditioned.fetchAbortController : fetchAbortController;

	if (fetchAbortController) fetchAbortController.abort();
};

export const getData = object => (dispatch) => {
	const typesWithAbort = [GET_POLICY_LIST, CP_GET_LIST, GET_GENERAL_CONDITIONED_LIST];

	if (typesWithAbort.includes(object.type)) {
		const fetchWithSignal = (signal) => auth.fetch(object.url, { signal });

		return executePromiseWithAbortSignal(dispatch, object, fetchWithSignal);
	}

	return executePromise(dispatch, object, auth.fetch(object.url));
};

export const postData = object => (dispatch) => {
  return executePromise(dispatch, object, auth.fetch(object.url, {
    method: 'POST',
    body: JSON.stringify(object.config),
    headers: {
      'Content-Type': 'application/json',
    },
  }));
};

export const patchData = object => (dispatch) => {
  return executePromise(dispatch, object, auth.fetch(object.url, {
    method: 'PATCH',
    body: JSON.stringify(object.config),
    headers: {
      'Content-Type': 'application/json',
    },
  }));
};

export const uploadFile = object => (dispatch) => {
  return executePromise(dispatch, object, auth.fetch(object.url, {
    method: 'PUT',
    body: object.config.body,
    headers: {
      'Content-Disposition': `attachment; filename="${encodeURI(object.config.name)}"`,
    },
  }));
};

export const deleteData = object => (dispatch) => {
  dispatch({ type: startType(object.type) });
  return executePromise(dispatch, object,
    auth.fetch(object.url, {
      method: 'DELETE',
      body: '',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(() => dispatch(({ type: object.type, payload: { id: object.id } })))
      .catch((error) => {
        dispatch({ type: errorType(object.type), payload: error });
        if (object.onFailedReq) {
          object.onFailedReq();
        }
      })
  );
};
