/* eslint-disable camelcase */
import {
	startType,
  ACTIVATE_GENERAL_CONDITIONED,
  GET_GENERAL_CONDITIONED_LIST,
  GET_GENERAL_CONDITIONED,
  GET_PRODUCTOS,
  GET_TIPOLOGIAS_EJECUCION,
  GET_TIPOS_COBERTURA,
  GET_TIPOS_INSTITUCION,
  GET_RAMOS_FECU,
  ACTIVATE_GENERAL_CONDITIONED_ERROR,
  SAVE_GENERAL_CONDITIONED_ERROR,
  CLEAN_GENERAL_CONDITIONED,
  CREATE_GENERAL_CONDITIONED,
  SAVE_GENERAL_CONDITIONED,
  UPLOAD_FILE,
} from '../constants/action-types';

const initialState = {
  listData: {
    data: [],
    pages: 0,
    count: undefined,
    next: undefined,
    previous: undefined,
  },
  selectedPolicy: undefined,
  productos: [],
  tipologias_ejecucion: [],
  tipos_cobertura: [],
  tipos_institucion: [],
  ramos_fecu: [],
  documento_uuid: undefined,
  errorMessage: undefined,
  successMessage: false,
	fetchAbortController: undefined,
};

const setAbortController = (state, action) => ({
	...state,
	fetchAbortController: action.abortController
});

const executeGetPolicyListSuccess = (state, action) => ({
  ...state,
  listData: {
    data: action.payload.results,
    pages: Math.ceil(action.payload.count / 20),
    count: action.payload.count,
    next: action.payload.next,
    previous: action.payload.previous,
  },
  successMessage: undefined,
  errorMessage: undefined,
});

const executeGetPolicySuccess = (state, action) => ({
  ...state,
  selectedPolicy: action.payload,
  errorMessage: undefined,
});

const executeGetProductListSuccess = (state, action) => ({
  ...state,
  productos: action.payload,
  errorMessage: undefined,
});

const executeGetCoveringTypesListSuccess = (state, action) => ({
  ...state,
  tipos_cobertura: action.payload,
  errorMessage: undefined,
});

const executeGetRamosFecuListSuccess = (state, action) => ({
  ...state,
  ramos_fecu: action.payload,
  errorMessage: undefined,
});

const executeGetInstitutionTypesListSuccess = (state, action) => {
  const tipos_institucion = action.payload.map(node => ({ label: node.descripcion, value: node }));
  return {
    ...state,
    tipos_institucion,
    errorMessage: undefined,
  };
};

const executeGetExecutionTipologySuccess = (state, action) => ({
  ...state,
  tipologias_ejecucion: action.payload,
  errorMessage: undefined,
});

const executeCreatePolicySuccess = (state, action) => ({
  ...state,
  successMessage: !!action.payload || false,
});

const executeSavePolicySuccess = (state, action) => ({
  ...state,
  successMessage: !!action.payload || false,
  errorMessage: undefined,
});

const executeUploadFileSuccess = (state, action) => ({
  ...state,
  documento_uuid: action.payload.uuid,
  errorMessage: undefined,
});

const executeActivatePolicySuccess = (state, action) => {
  const list = state.listData.data;
  const updatedList = [];
  list.map(node => node.id === action.payload.id
    ? updatedList.push(action.payload) : updatedList.push(node));
  return {
    ...state,
    listData: {
      ...state.listData,
      data: updatedList,
    },
    errorMessage: undefined,
  };
};

const executeCleanPolicySuccess = state => ({
  ...state,
  selectedPolicy: undefined,
  errorMessage: undefined,
  productos: [],
  tipologias_ejecucion: [],
  tipos_cobertura: [],
  successMessage: false,
});

const executeErrorMessage = (state, action) => ({
  ...state,
  errorMessage: action.payload.response
    ? action.payload.response.data
    : 'No se pudo completar la acción, por favor inténtelo más tarde',
});

function conditioned(state = initialState, action) {
  switch (action.type) {
    case GET_GENERAL_CONDITIONED:
      return executeGetPolicySuccess(state, action);
    case GET_PRODUCTOS:
      return executeGetProductListSuccess(state, action);
    case GET_TIPOS_COBERTURA:
      return executeGetCoveringTypesListSuccess(state, action);
    case GET_RAMOS_FECU:
      return executeGetRamosFecuListSuccess(state, action);
    case GET_TIPOS_INSTITUCION:
      return executeGetInstitutionTypesListSuccess(state, action);
    case GET_TIPOLOGIAS_EJECUCION:
      return executeGetExecutionTipologySuccess(state, action);
    case startType(GET_GENERAL_CONDITIONED_LIST):
      return setAbortController(state, action);
    case GET_GENERAL_CONDITIONED_LIST:
      return executeGetPolicyListSuccess(state, action);
    case CREATE_GENERAL_CONDITIONED:
      return executeCreatePolicySuccess(state, action);
    case SAVE_GENERAL_CONDITIONED:
      return executeSavePolicySuccess(state, action);
    case ACTIVATE_GENERAL_CONDITIONED:
      return executeActivatePolicySuccess(state, action);
    case CLEAN_GENERAL_CONDITIONED:
      return executeCleanPolicySuccess(state, action);
    case UPLOAD_FILE:
      return executeUploadFileSuccess(state, action);
    case ACTIVATE_GENERAL_CONDITIONED_ERROR:
      return executeErrorMessage(state, action);
    case SAVE_GENERAL_CONDITIONED_ERROR:
      return executeErrorMessage(state, action);
    default:
      return state;
  }
}

export default conditioned;
