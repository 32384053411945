import React, { useState, useEffect } from 'react';
import { Button, FormGroup, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Datetime from 'react-datetime';
import { connect } from 'react-redux';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import {
  CP_ADD_PLAN_PAGO_CUOTA,
  CP_DELETE_PLAN_PAGO_CUOTA,
  CP_EDIT_PLAN_PAGO_CUOTA,
} from '../../../../redux-store/constants/action-types';
import {
  FRONT_DF_2,
  IVA_PCT,
  PLAN_PAGO_MAX_CUOTAS_ANUALES,
} from '../../../../CONST';
import {
  cellNumOperation,
  cellOperation,
  formatValue,
  headerOperation,
} from '../../../../helpers/tables';
import BaseTable from '../../../UI/BaseTable';
import { areClose } from '../../../../helpers/helpers';
import { isEndosoModificacion } from '../../../../helpers/validation';

const TablaCuotasPlan = ({
  dispatch,
  creditPolicy,
  tipoVigencia,
  validate,
  disabled,
}) => {
  const {
    form,
    form: {
      data: {
        endoso,
        endoso: {
          planPago: { cuotasPlan },
          inicioVigenciaEndoso,
          deltaPrimaMinimaAnualAmt,
        },
      },
      cambioPrima,
      ultimoEndosoVigencia,
    },
  } = creditPolicy;
  const [neto, setNeto] = useState();
  const [fecha, setFecha] = useState();
  const [fechaInicial, setFechaInicial] = useState(
    moment(form.data.endoso.inicioVigencia),
  );
  const [editing, setEditing] = useState(false);
  useEffect(() => {
    if (!form.data.endoso.inicioVigenciaEndoso) {
      setFecha(null);
    }
  }, [!!form.data.endoso.inicioVigenciaEndoso]);
  const moneda = form.data.moneda ? form.data.moneda.value.nombre_corto : '';
  const isModificacion = isEndosoModificacion(form);

  // nCuotasIsValid
  const maxCuotas = tipoVigencia.years * PLAN_PAGO_MAX_CUOTAS_ANUALES;
  const cuotasPendientes = cuotasPlan.reduce((total, cuota) => {
    if (
      inicioVigenciaEndoso &&
      cuota.fecha_pago.isSameOrAfter(inicioVigenciaEndoso, 'days')
    ) {
      return total + 1;
    }
    return total;
  }, 0);

  const cuotasBalance = ultimoEndosoVigencia
    ? ultimoEndosoVigencia.plan_pago.cuotas_plan.length
    : 0;
  let nCuotasIsValid;
  if (deltaPrimaMinimaAnualAmt !== 0) {
    nCuotasIsValid = cuotasPendientes <= maxCuotas;
  } else if (cambioPrima) {
    nCuotasIsValid = cuotasPlan.length <= Math.max(maxCuotas, cuotasBalance);
  } else {
    nCuotasIsValid = cuotasPlan.length <= maxCuotas;
  }

  // netoIsValid
  const netoSum = cuotasPlan.reduce(
    (total, cuota) => total + cuota.neto_amt,
    0,
  );
  const netoTarget = tipoVigencia.years * (endoso.primaMinimaAnualAmt || 0);
  const netoIsValid = areClose(netoSum, netoTarget);

  const ivaSum = cuotasPlan.reduce((total, cuota) => total + cuota.iva_amt, 0);
  const totalSum = cuotasPlan.reduce(
    (total, cuota) => total + cuota.total_amt,
    0,
  );

  const datesIsValid = cuotasPlan.every(
    cuota =>
      cuota.fecha_pago.isSameOrAfter(endoso.inicioVigencia, 'days') &&
      cuota.fecha_pago.isSameOrBefore(endoso.terminoVigencia, 'days'),
  );

  const isBtnDisabled =
    !neto ||
    !fecha ||
    (!editing &&
      (deltaPrimaMinimaAnualAmt !== 0
        ? cuotasPendientes >= maxCuotas
        : cuotasPlan.length >= maxCuotas));

  const disabledC1 = cuotasPendientes >= maxCuotas;
  const disabledC2 = cambioPrima
    ? cuotasPlan.length >= Math.max(maxCuotas, cuotasBalance)
    : cuotasPlan.length >= maxCuotas;
  const cuotasDisabled =
    (deltaPrimaMinimaAnualAmt !== 0 && disabledC1) ||
    (deltaPrimaMinimaAnualAmt === 0 && disabledC2);

  const handleEditPlanPagoCuota = () => {
    dispatch({
      type: CP_EDIT_PLAN_PAGO_CUOTA,
      payload: {
        ...editing,
        neto_amt: neto,
        fecha_pago: fecha,
        iva_amt: neto * IVA_PCT,
        total_amt: neto * (1 + IVA_PCT),
        selectedRow: undefined,
      },
    });
    setEditing(false);
    setFechaInicial(fecha);
    setFecha(null);
  };

  const handleAddPlanPagoCuota = () => {
    dispatch({
      type: CP_ADD_PLAN_PAGO_CUOTA,
      payload: {
        neto_amt: neto,
        fecha_pago: fecha,
        iva_amt: neto * IVA_PCT,
        total_amt: neto * (1 + IVA_PCT),
      },
    });
    setFechaInicial(fecha);
    setFecha(null);
  };

  const columns = [
    {
      id: 'cuota',
      Header: headerOperation('#'),
      accessor: data => cellOperation(data.numero),
      width: 40,
    },
    {
      id: 'neto',
      Header: headerOperation('Monto Neto'),
      Footer: headerOperation(formatValue(netoSum, true), 'right'),
      accessor: data => cellNumOperation(data.neto_amt),
    },
    {
      id: 'iva',
      Header: headerOperation('IVA'),
      Footer: headerOperation(formatValue(ivaSum, true), 'right'),
      accessor: data => cellNumOperation(data.iva_amt),
    },
    {
      id: 'bruto',
      Header: headerOperation('Monto Bruto'),
      Footer: headerOperation(formatValue(totalSum, true), 'right'),
      accessor: data => cellNumOperation(data.total_amt),
    },
    {
      id: 'fechaPago',
      Header: headerOperation('Fecha de Pago'),
      accessor: data => cellOperation(data.fecha_pago.format(FRONT_DF_2)),
      width: 100,
    },
  ];

  if (!disabled) {
    const actionColumn = {
      id: 'acciones',
      Header: headerOperation('Acciones'),
      Cell: row =>
        cellOperation(
          <div className="text-center">
            <button
              type="button"
              className="table-link"
              onClick={() => {
                dispatch({
                  type: CP_EDIT_PLAN_PAGO_CUOTA,
                  payload: { ...row.original, selectedRow: true },
                });
                setEditing(row.original);
                setNeto(row.original.neto_amt);
                setFecha(row.original.fecha_pago);
              }}
              title="editar"
              disabled={!!editing}
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
            <button
              type="button"
              className="table-link"
              onClick={() =>
                dispatch({
                  type: CP_DELETE_PLAN_PAGO_CUOTA,
                  payload: row.original,
                })
              }
              title="eliminar"
              style={{ marginLeft: '10px' }}
              disabled={!!editing}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>,
        ),
      width: 80,
    };

    columns.push(actionColumn);
  }

  return (
    <>
      {!disabled && (
        <div
          style={{
            paddingLeft: '20px',
            display: 'grid',
            gridTemplateColumns: '2fr 2fr 1fr',
            gridGap: '10px',
          }}
        >
          <FormGroup>
            <Label>Monto Neto</Label>
            <NumberFormat
              id="ce-monto-neto-cuota"
              name="netoCuota"
              className="form-control"
              allowNegative={false}
              thousandSeparator="."
              decimalSeparator=","
              fixedDecimalScale
              value={neto}
              onValueChange={values => setNeto(values.floatValue)}
              suffix={` ${moneda}`}
            />
          </FormGroup>
          <FormGroup>
            <Label>Fecha de Pago</Label>
            <Datetime
              closeOnSelect
              dateFormat={FRONT_DF_2}
              timeFormat={false}
              viewDate={fechaInicial}
              id="fecha-de-pago"
              locale="es"
              value={fecha}
              onChange={setFecha}
              inputProps={{
                disabled: !form.data.endoso.inicioVigenciaEndoso,
                readOnly: true,  // Deshabilita la entrada manual
              }}
              isValidDate={date =>
                moment(date).isSameOrAfter(
                  moment(form.data.endoso.inicioVigenciaEndoso),
                  'days',
                ) &&
                moment(date).isSameOrBefore(
                  moment(form.data.endoso.terminoVigencia),
                  'days',
                ) &&
                (deltaPrimaMinimaAnualAmt !== 0
                  ? moment(date).isSameOrAfter(
                      moment(inicioVigenciaEndoso),
                      'days',
                    )
                  : true)
              }
            />
          </FormGroup>
          <FormGroup style={{ display: 'grid', alignItems: 'end' }}>
            <Button
              type="button"
              className=""
              color="orsan-secondary"
              style={{ fontSize: '14px', marginLeft: '10px' }}
              disabled={isBtnDisabled}
              onClick={
                editing ? handleEditPlanPagoCuota : handleAddPlanPagoCuota
              }
            >
              {editing ? 'Guardar' : 'Agregar'}
            </Button>
          </FormGroup>
        </div>
      )}
      <div className="plan-pago-feedback">
        {validate && !netoIsValid && (
          <p>{`El total neto debe ser ${formatValue(netoTarget, true)}.`}</p>
        )}
        {validate && !nCuotasIsValid && deltaPrimaMinimaAnualAmt === 0 && (
          <p>{`No puede haber más de ${maxCuotas} cuotas.`}</p>
        )}
        {validate && !nCuotasIsValid && deltaPrimaMinimaAnualAmt !== 0 && (
          <p>{`Si se cambia la prima, no puede haber más de ${maxCuotas} cuotas pendientes.`}</p>
        )}
        {validate && !datesIsValid && (
          <p>
            Las fechas deben estar contenidas entre el inicio y termino de
            vigencia de la póliza.
          </p>
        )}
        {!form.data.endoso.inicioVigenciaEndoso && !disabled && (
          <p>
            {`Debe indicar fecha de inicio vigencia ${
              isModificacion ? 'de endoso' : 'de póliza'
            }.`}
          </p>
        )}
        {cuotasDisabled && (
          <p style={{ color: '#315E90' }}>No se pueden agregar más cuotas.</p>
        )}
      </div>
      <BaseTable
        columns={columns}
        data={cuotasPlan}
        sortable={false}
        resizable={false}
        shrink
      />
    </>
  );
};

TablaCuotasPlan.propTypes = {
  dispatch: PropTypes.func.isRequired,
  creditPolicy: PropTypes.shape({
    form: PropTypes.shape({
      data: PropTypes.shape({
        moneda: PropTypes.object,
        endoso: PropTypes.shape({
          inicioVigencia: PropTypes.object,
          inicioVigenciaEndoso: PropTypes.object,
          terminoVigencia: PropTypes.object,
          primaMinimaAnualAmt: PropTypes.number,
          deltaPrimaMinimaAnualAmt: PropTypes.number,
          estado: PropTypes.string,
          planPago: PropTypes.shape({
            cuotasPlan: PropTypes.arrayOf(
              PropTypes.shape({
                neto_amt: PropTypes.number,
                fecha_pago: PropTypes.object,
              }),
            ),
          }),
        }),
      }),
      cambioPrima: PropTypes.bool,
      ultimoEndosoVigencia: PropTypes.shape({
        plan_pago: PropTypes.shape({
          cuotas_plan: PropTypes.array,
        }),
      }),
    }),
  }).isRequired,
  tipoVigencia: PropTypes.shape({
    years: PropTypes.number,
  }).isRequired,
  validate: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

TablaCuotasPlan.defaultProps = {
  disabled: false,
};

const mapStateToProps = state => ({
  creditPolicy: state.creditPolicy,
});

export default connect(mapStateToProps)(TablaCuotasPlan);
